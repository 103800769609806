<template>

<div class="badge">

	<div class="badge-summary" :data-theme="theme">

		<div class="badge-summary-name">{{ item.badge.name }}</div>
		<div class="badge-summary-reference">Ref. {{ item.badge.reference_random }}</div>

	</div>
		
	<div class="badge-info" v-if="hasExactTicket" :data-theme="theme">

		<app-icon icon="tick" class="badge-info-icon" />
		<div class="badge-info-text" v-if="!hasScanned">This attendee has a ticket for the event.</div>
		<div class="badge-info-text" v-if="hasScanned">This attendee has already scanned their ticket for the event.</div>

	</div>
		
	<div class="badge-info" v-if="hasSimilarTicket" :data-theme="theme">

		<app-icon icon="warning" class="badge-info-icon" />
		<div class="badge-info-text" v-if="item.event">This attendee has an unused ticket of this type for another event that can be used here instead.</div>
		<div class="badge-info-text" v-if="!item.event">This attendee has an unused ticket of this type that can be used here.</div>

	</div>
		
	<div class="badge-info" v-if="!hasTicket" :data-theme="theme">

		<app-icon icon="cross" class="badge-info-icon" />
		<div class="badge-info-text" v-if="hasReachedLimit">This attendee does not have a ticket and has already used up their limit for this type of ticket.</div>
		<div class="badge-info-text" v-if="!hasReachedLimit">This attendee does not have a ticket. You can still admit at your own discretion.</div>

	</div>

	<div class="badge-actions">

		<app-button v-if="!item.scanned && !hasReachedLimit" text="Admit" :loading="is.saving" :theme="theme" v-on:click="onConfirmClick" />
		<app-button :disabled="is.saving" :text="(item.scanned || hasReachedLimit) ? 'Close' : 'Cancel'" theme="grey" v-on:click="$emit('cancel')" />

	</div>

</div>

</template>

<script>

export default {

	props: ['item', 'used', 'limit', 'event'],

	data: function() {

		return {
			is: {
				saving: false
			}
		}

	},

	computed: {

		hasTicket: function() {

			return this.item.id 

		},

		hasReachedLimit: function() {

			return this.used >= this.limit

		},

		hasExactTicket: function() {

			return this.item.id && this.item.event === this.event.id

		},
		
		hasSimilarTicket: function() {

			return this.item.id && this.item.event !== this.event.id

		},

		hasScanned: function() {

			return this.item.scanned

		},

		theme: function() {

			if (this.item.id) {

				if (this.item.event === this.event.id) {

					return 'green'

				} else {

					return 'yellow'

				}

			} else {

				return (this.hasReachedLimit) ? 'red' : 'yellow'

			}

		}

	},

	methods: {

		onConfirmClick: function() {

			this.is.saving = true

			this.$api.post(['tickets/event',  this.event.id, 'check', this.item.badge.id]).then(function() {
				
				this.$emit('admit')
				
				this.is.saving = false

			}.bind(this), function() {

				this.is.saving = false

				this.$notify({
					message: 'Sorry, could not admit attendee.',
					type: 'error'
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.badge {
	background-color: #fff;
	display: flex;
	flex-grow: 1;
	padding: 0px!important;
	flex-direction: column;
}

.badge-summary {
	padding: 20px 10px;
	flex-shrink: 0;
}

.badge-summary[data-theme="green"] {
	background-color: #4faa80;
}

.badge-summary[data-theme="yellow"] {
	background-color: #ffbe7a;
}

.badge-summary[data-theme="red"] {
	background-color: #c55b5b;
}

.badge-summary-name {
	text-align: center;
	font-weight: 400;
	color: #fff;
	font-size: 24px;
}

.badge-summary-reference {
	text-align: center;
	font-weight: 300;
	color: rgba(255, 255, 255, 0.75);
	font-size: 14px;
	margin-top: 4px;
}

.badge-summary-email {
	text-align: center;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.75);
	font-size: 14px;
	margin-top: 20px;
}

.badge-summary-type {
	text-align: center;
	padding: 10px 20px;
	font-weight: 400;
	color: #fff;
}

.badge-info {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	flex-direction: column;
}

.badge-info[data-theme="green"] {
	color: #4faa80;
}

.badge-info[data-theme="yellow"] {
	color: #ffbe7a;
}

.badge-info[data-theme="red"] {
	color: #c55b5b;
}

.badge-info-icon {
	font-size: 96px;
}

.badge-info-text {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	text-align: center;
	margin-top: 20px;
}

.badge-actions {
	flex-shrink: 0;
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	background-color: #384352;
}

.badge-actions >>> .button {
	width: calc(50% - 10px);
}

</style>
